import React, { useCallback } from 'react'
import ListItem from '@mui/material/ListItem'
import Typography from '@mui/material/Typography'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import SvgIcon from '@mui/material/SvgIcon'
import { Link, useHistory } from 'react-router-dom'
import { useAppSelector } from '../../states/app/hooks'
import List from '@mui/material/List'
import UserMenu from './config/UserMenu'
import AdminMenu from './config/AdminMenu'
import { selectLogin } from '../../states/features/User/LoginSlice'
import { selectAside } from '../../states/features/Aside/AsideSlice'
import { yellow } from '@mui/material/colors'
import { menuProps } from './config/UserMenu'
import { Button, Divider } from '@mui/material'
import LazyImage from '../LazyImage'
import { Box } from '@mui/system'
import { Link as MUILink } from '@mui/material'

interface menuPropsExtend extends menuProps {
    currentAside: string
    setOpenMenuLeft: React.Dispatch<React.SetStateAction<boolean>>
}

const ListItemButtonExtend = (props: menuPropsExtend) => {
    const { href, icon, name, asideName, currentAside, setOpenMenuLeft } = props
    const history = useHistory()
    const handleClick = useCallback(
        (link: string) => {
            history.push(link)
            setOpenMenuLeft((pre) => !pre)
        },
        [history, setOpenMenuLeft]
    )
    return (
        <>
            <ListItemButton sx={{ display: { xs: 'none', md: 'flex' } }} component={asideName !== 'Home' ? Link : MUILink} to={href} href={href}>
                <ListItemIcon sx={{ minWidth: '40px' }}>
                    <SvgIcon sx={{ color: asideName === currentAside ? yellow['A400'] : 'inherit' }} component={icon} inheritViewBox />
                </ListItemIcon>
                <ListItemText primary={<Typography sx={{ fontWeight: asideName === currentAside ? '700' : '400', color: asideName === currentAside ? yellow['A400'] : 'inherit' }}>{name}</Typography>} />
            </ListItemButton>
            <ListItemButton
                sx={{ display: { xs: 'flex', md: 'none' } }}
                onClick={() => {
                    handleClick(href)
                }}
            >
                <ListItemIcon sx={{ minWidth: '40px' }}>
                    <SvgIcon sx={{ color: asideName === currentAside ? yellow['A400'] : 'inherit' }} component={icon} inheritViewBox />
                </ListItemIcon>
                <ListItemText primary={<Typography sx={{ fontWeight: asideName === currentAside ? '700' : '400', color: asideName === currentAside ? yellow['A400'] : 'inherit' }}>{name}</Typography>} />
            </ListItemButton>
        </>
    )
}

interface Props {
    setOpenMenuLeft: React.Dispatch<React.SetStateAction<boolean>>
}
const UserMenuLeft: React.FC<Props> = ({ setOpenMenuLeft }) => {
    const { username, role } = useAppSelector(selectLogin)
    const { asideName } = useAppSelector(selectAside)
    return (
        <List>
            <ListItem disablePadding sx={{ justifyContent: 'center' }}>
                <LazyImage src="/logo192.png?v=2" alt="Ms" sx={{ width: '80px', height: '80px' }} />
            </ListItem>

            {username ? (
                <>
                    <ListItem sx={{ justifyContent: 'space-between', display: 'flex', alignItems: 'center' }}>
                        {/*<LazyImage src="/images/LeftMenu/default-user-avatar.png" alt="Etoro VN Win" sx={{ width: '50px', height: '50px' }} />*/}
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography>{username}</Typography>
                            <Typography color={yellow['A400']} fontWeight={700} component={Link} to="/ho-so-tai-khoan.html" sx={{ mt: 0.5, textDecoration: 'none' }}>
                                Xem hồ sơ
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Button color="warning" variant="contained" sx={{ textTransform: 'capitalize', p: 0.4, py: 0.1 }}>
                                Member
                            </Button>
                            <Typography color={yellow['A400']} fontWeight={700} onClick={() => setOpenMenuLeft(false)} component={Link} to="/danh-sach-goi-dau-tu.html" sx={{ mt: 0.5, textDecoration: 'none' }}>
                                Nâp cấp
                            </Typography>
                        </Box>
                    </ListItem>
                </>
            ) : (
                <ListItem sx={{ justifyContent: 'space-between', display: 'flex', alignItems: 'center' }}>
                    {/*<LazyImage src="/images/LeftMenu/default-user-avatar.png" alt="Etoro VN Win" sx={{ width: '50px', height: '50px' }} />*/}
                    <Button component={Link} to="/dang-nhap.html" color="warning" variant={asideName === 'Login' ? 'contained' : 'outlined'} size="small" sx={{ textTransform: 'capitalize' }}>
                        Đăng nhập
                    </Button>
                    <Button component={Link} to="dang-ky-tai-khoan.html" color="error" variant={asideName === 'Register' ? 'contained' : 'outlined'} size="small" sx={{ textTransform: 'capitalize' }}>
                        Đăng ký
                    </Button>
                </ListItem>
            )}

            <Divider sx={{ pt: 2 }} />
            {UserMenu.map((obj, index) => (
                <ListItem key={index} disablePadding>
                    <ListItemButtonExtend {...obj} currentAside={asideName} setOpenMenuLeft={setOpenMenuLeft} />
                </ListItem>
            ))}
            {role.admin && (
                <>
                    <ListItem disablePadding>
                        <Typography sx={{ fontWeight: 600, ml: 2 }} variant="h6" color={yellow['A400']}>
                            Admin
                        </Typography>
                    </ListItem>
                    {AdminMenu.map((obj, index) => (
                        <ListItem key={index} disablePadding>
                            <ListItemButtonExtend {...obj} currentAside={asideName} setOpenMenuLeft={setOpenMenuLeft} />
                        </ListItem>
                    ))}
                </>
            )}
        </List>
    )
}
export default UserMenuLeft
