import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { apiPost } from "../../../hooks/api";
import { RootState } from "../../app/store";

interface ActiveState {
  username: string;
  email: string;
  status: "idle" | "loading" | "failed" | "succeeded";
}

interface activePosts {
  id: string;
}

const initialState: ActiveState = {
  username: "",
  email: "",
  status: "idle",
};

export const ActiveAsync = createAsyncThunk(
  "user/active",
  async (data: activePosts) => {
    const newLocal = "user/sign-up/active";
    return await apiPost({ ...data }, newLocal);
  }
);

export const ActiveSlice = createSlice({
  name: "user/active",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(ActiveAsync.pending, (state) => {
        return { ...state, status: "loading" };
      })
      .addCase(ActiveAsync.fulfilled, (state, actions: PayloadAction<any>) => {
        return {
          ...state,
          status: "succeeded",
          username: actions.payload.username,
          email: actions.payload.email,
        };
      })
      .addCase(ActiveAsync.rejected, (state) => {
        return { ...state, status: "failed" };
      });
  },
});
export const selectActive = (state: RootState) => state.active;
export default ActiveSlice.reducer;
