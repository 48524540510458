import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import useApi from '../../../../hooks/useApi'
import { RootState } from '../../../app/store'
import { loginState } from '../../User/LoginSlice'

interface CountUser {
    type: 'customer' | 'admin'
    count: number
    parent_ref: string
}

interface GetAdminAllUser {
    items: Array<loginState>
    itemSearch: Array<loginState>
    totalUser: Array<CountUser>
    message: string
    status: 'idle' | 'loading' | 'failed' | 'sucessded'
    statusSearch: 'idle' | 'loading' | 'failed' | 'sucessded'
}

const initialState: GetAdminAllUser = {
    items: [],
    itemSearch: [],
    totalUser: [],
    message: '',
    status: 'idle',
    statusSearch: 'idle',
}
interface getProps {
    token: string
    page: number
    type: string
}

interface searchProps {
    token: string
    search: string
    type: string
    page: number
}

export const adminGetAllUserAsync = createAsyncThunk('admin/user/get-all', async (data: getProps) => {
    const { apiPost } = useApi()
    return await apiPost({ ...data, token: '' }, `admin/user/get-all?access_token=${data.token}`)
})

export const adminSearchUserAsync = createAsyncThunk('admin/user/search', async (data: searchProps) => {
    const { apiPost } = useApi()
    return await apiPost({ ...data, token: '' }, `admin/user/search?access_token=${data.token}`)
})
export const adminGetAllUserSlice = createSlice({
    name: 'admin/user',
    initialState,
    reducers: {
        clearState: (state) => {
            return {
                ...state,
                status: 'idle',
                message: '',
                item: initialState.items,
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(adminGetAllUserAsync.fulfilled, (state, { payload }) => {
                const { items, totalUser } = payload as GetAdminAllUser
                return {
                    ...state,
                    status: 'sucessded',
                    items,
                    totalUser,
                    message: '',
                }
            })
            .addCase(adminGetAllUserAsync.rejected, (state, { error }) => {
                return {
                    ...state,
                    status: 'failed',
                    message: error.message as string,
                    item: initialState.items,
                    totalUser: initialState.totalUser,
                }
            })
            .addCase(adminGetAllUserAsync.pending, (state) => {
                return {
                    ...state,
                    statusSearch: 'loading',
                    items: initialState.items,
                    message: '',
                    totalUser: initialState.totalUser,
                }
            })
            //Search ------------------------------------------------------------------------------
            .addCase(adminSearchUserAsync.fulfilled, (state, { payload }) => {
                const { itemSearch } = payload as GetAdminAllUser
                return {
                    ...state,
                    statusSearch: 'sucessded',
                    itemSearch,
                    message: '',
                }
            })
            .addCase(adminSearchUserAsync.rejected, (state, { error }) => {
                return {
                    ...state,
                    statusSearch: 'failed',
                    message: error.message as string,
                    itemSearch: initialState.itemSearch,
                }
            })
            .addCase(adminSearchUserAsync.pending, (state) => {
                return {
                    ...state,
                    statusSearch: 'loading',
                    message: '',
                    itemSearch: initialState.itemSearch,
                }
            })
    },
})

export const { clearState } = adminGetAllUserSlice.actions
export const selectAdminGetAllUser = (state: RootState) => state.adminGetAllUser
export default adminGetAllUserSlice.reducer
