import React from 'react'
import { MdOutlineAttachMoney, MdOutlineAvTimer } from 'react-icons/md'
import { AiOutlinePlusCircle, AiOutlineSwap, AiOutlineUsergroupAdd } from 'react-icons/ai'
import { BiCube, BiPulse, BiShareAlt, BiTrendingUp, BiWallet } from 'react-icons/bi'
export interface menuProps {
    name: string
    asideName: string
    href: string
    icon: React.ElementType
}
const UserMenuLeftConfig = [
    // {
    //     name: 'Trang chủ',
    //     asideName: 'Home',
    //     href: 'https://bmlobal.vip',
    //     icon: AiOutlineHome,
    // },
    {
        name: 'Gói của tôi',
        asideName: 'MyPackages',
        href: '/goi-dau-tu-cua-toi.html',
        icon: BiCube,
    },
    {
        name: 'Nhật ký lãi suất',
        asideName: 'LogInterest',
        href: '/nhat-ky-lai-suat.html',
        icon: BiTrendingUp,
    },
    {
        name: 'Lịch sử trả thưởng',
        asideName: 'LogPacketSystem',
        href: '/lich-su-tra-thuong.html',
        icon: MdOutlineAvTimer,
    },

    {
        name: 'Danh sách gói',
        asideName: 'ListPacket',
        href: '/danh-sach-goi-dau-tu.html',
        icon: BiPulse,
    },
    {
        name: 'Nạp coin',
        asideName: 'Deposit',
        href: '/nap-coin.html',
        icon: AiOutlinePlusCircle,
    },
    {
        name: 'Rút coin',
        asideName: 'WithDraw',
        href: '/rut-coin.html',
        icon: BiWallet,
    },
    {
        name: 'Swap BMG => USDT',
        asideName: 'SwapCoin',
        href: '/swap-coin-usdt.html',
        icon: AiOutlineSwap,
    },
    {
        name: 'Swap USDT => BMG',
        asideName: 'SwapUsdt',
        href: '/swap-usdt-coin.html',
        icon: MdOutlineAttachMoney,
    },

    // {
    //     name: 'Nhật ký hủy',
    //     asideName: 'LogCancel',
    //     href: '/nhat-ky-huy-goi-dau-tu.html',
    //     icon: MdFreeCancellation,
    // },
    {
        name: 'Mời bạn bè',
        asideName: 'ShareLink',
        href: '/moi-ban-be.html',
        icon: BiShareAlt,
    },
    {
        name: 'Cây hệ thống',
        asideName: 'Tree',
        href: '/cay-he-thong.html',
        icon: AiOutlineUsergroupAdd,
    },
] as Array<menuProps>

export default UserMenuLeftConfig
