import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import useApi from '../../../../hooks/useApi'
import { RootState } from '../../../app/store'

export interface itemProps {
    depositAllTime: string
    depositDay: string
    packetAllTime: string
    packetDay: string
    packetCancelAllTime: string
    packetCancelDay: string
    returnDay: string
    returnProfit: string
    message: string
    status: 'idle' | 'loading' | 'failed' | 'sucessded'
}

const initialState: itemProps = {
    depositAllTime: '0đ',
    depositDay: '0đ',
    packetAllTime: '0đ',
    packetDay: '0đ',
    packetCancelAllTime: '0đ',
    packetCancelDay: '0đ',
    returnDay: '0đ',
    returnProfit: '0đ',
    status: 'idle',
    message: '',
}

interface getProps {
    token: string
}
export const adminGetInfoAsync = createAsyncThunk('admin/info/get', async (data: getProps) => {
    const { apiGet } = useApi()
    const { token } = data
    return await apiGet(`admin/get-info?access_token=${token}`)
})

export const adminGetInfoSlice = createSlice({
    name: 'admin/info',
    initialState,
    reducers: {
        clearLogDepositState: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(adminGetInfoAsync.fulfilled, (state, { payload }) => {
                const { returnProfit, returnDay, depositAllTime, depositDay, packetAllTime, packetDay, packetCancelAllTime, packetCancelDay } = payload as itemProps
                return {
                    ...state,
                    status: 'sucessded',
                    message: '',
                    depositAllTime,
                    depositDay,
                    packetAllTime,
                    packetDay,
                    packetCancelAllTime,
                    packetCancelDay,
                    returnDay,
                    returnProfit,
                }
            })
            .addCase(adminGetInfoAsync.rejected, (state, { error }) => {
                return {
                    ...state,
                    status: 'failed',
                    message: error.message as string,
                }
            })
            .addCase(adminGetInfoAsync.pending, (state) => {
                return {
                    ...state,
                    status: 'loading',
                    message: '',
                }
            })
    },
})

export const { clearLogDepositState } = adminGetInfoSlice.actions
export const selectAdmininfo = (state: RootState) => state.adminGetInfo
export default adminGetInfoSlice.reducer
