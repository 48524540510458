import React from 'react'
// import { Backdrop as MuiBackdrop, CircularProgress } from '@mui/material'
import { Backdrop as MuiBackdrop } from '@mui/material'
//import { yellow } from '@mui/material/colors'
import { useAppSelector } from '../../states/app/hooks'
import { selectBackdrop } from '../../states/features/Backdrop/BackdropSlice'

const Backdrop: React.FC = () => {
    const { show } = useAppSelector(selectBackdrop)
    return <MuiBackdrop sx={{ height: '100%', zIndex: (theme) => theme.zIndex.modal + theme.zIndex.tooltip }} open={show} />
}
export default Backdrop
