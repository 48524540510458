const ThemeConfig = {
    palette: { mode: 'dark' },
    typography: {
        fontFamily: ['Raleway', 'sans-serif'].join(','),
        fontSize: 16,
    },
    components: {
        MuiTypography: {
            defaultProps: {
                variantMapping: {
                    h1: 'h1',
                    h2: 'h2',
                    h3: 'h3',
                    h4: 'h4',
                    h5: 'h5',
                    h6: 'h6',
                    subtitle1: 'h2',
                    subtitle2: 'h2',
                    body1: 'p',
                    body2: 'span',
                    inherit: 'span',
                    caption: 'pre',
                },
            },
        },
    },
}

export default ThemeConfig
