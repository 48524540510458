import React from 'react'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { yellow } from '@mui/material/colors'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import Fab from '@mui/material/Fab'
import ScrollTop from './components/ScrollTop'
import { Box } from '@mui/system'
import { AiOutlineLogout, AiOutlineMenuUnfold } from 'react-icons/ai'
import { clearState as clearUser, selectLogin as selectUser } from '../../states/features/User/LoginSlice'
import { useAppDispatch, useAppSelector } from '../../states/app/hooks'
import Price from '../Price'

interface Props {
    window?: () => Window
    children: React.ReactElement
    openMenuLeft: boolean
    setOpenMenuLeft: React.Dispatch<React.SetStateAction<boolean>>
}
const PageHome: React.FC<Props> = (props: Props) => {
    const { children, openMenuLeft, setOpenMenuLeft } = props
    const dispatch = useAppDispatch()
    const { token, wallet } = useAppSelector(selectUser)
    return (
        <>
            <AppBar
                position="sticky"
                sx={{
                    background: (theme) => (theme.palette.mode === 'dark' ? '#27262c' : '#27262c'),
                }}
            >
                <Toolbar id="back-to-top-anchor" sx={{ paddingX: { xs: '0px' }, justifyContent: 'space-between' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                        {/* <iframe
                            title="Coin"
                            src="https://widget.coinlib.io/widget?type=horizontal_v2&theme=dark&pref_coin_id=1505&invert_hover=no"
                            width="100%"
                            height="40px"
                            scrolling="auto"
                            marginWidth={0}
                            marginHeight={0}
                            frameBorder="0"
                            style={{ border: 0, margin: 0, padding: 0 }}
                        ></iframe> */}
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', px: 0.5 }}>
                            <Box sx={{ textTransform: 'capitalize', display: { xs: 'none', sm: 'none', md: 'flex', width: '62px' } }}></Box>
                            <IconButton onClick={() => setOpenMenuLeft(!openMenuLeft)} coloraria-label="fingerprint" color="warning" sx={{ display: { xs: 'flex', sm: 'flex', md: 'none' } }}>
                                <AiOutlineMenuUnfold size="30" fontWeight={900} />
                                <Typography sx={{ fontWeight: '700' }}>Menu</Typography>
                            </IconButton>
                            {token && (
                                <>
                                    <Price num={wallet.balance} variant="body2" sx={{ fontSize: '1.3rem !important', color: yellow['A400'], textAlign: 'center', fontWeight: 'bold' }} />
                                    <IconButton
                                        onClick={() => {
                                            dispatch(clearUser())
                                        }}
                                        coloraria-label="fingerprint"
                                        color="primary"
                                    >
                                        <AiOutlineLogout size="30" fontWeight={900} />
                                        <Typography sx={{ fontWeight: '700', color: yellow['A400'] }}>Thoát</Typography>
                                    </IconButton>
                                </>
                            )}
                        </Box>
                    </Box>
                </Toolbar>
            </AppBar>
            {children}

            <Toolbar />
            <ScrollTop {...props}>
                <Fab color="primary" size="large" aria-label="scroll back to top">
                    <ArrowUpwardIcon />
                </Fab>
            </ScrollTop>
        </>
    )
}
export default PageHome
