import { IntlProvider } from 'react-intl'
import chooseLocale from './hooks/Localization'
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { createTheme, responsiveFontSizes } from '@mui/material'
import ThemeConfig from './config/theme'
import { useAppDispatch, useAppSelector } from './states/app/hooks'
import { selectLocal, changeLocale } from './states/features/Locale/LocalSlice'
import { lazy, useEffect, useMemo, useState } from 'react'
import ColorModeContext from './context/ColorMode'

import { Router, Route, Switch, Redirect } from 'react-router-dom'
import history from './routerHistory'
import PageLoader from './component/Loader/PayLoader'
import SuspenseWithChunkError from './component/SuspenseWithChunkError'
import Page from './views/Page'
import { SnackbarProvider } from 'notistack'
import { LoadingUserAsync, selectLogin } from './states/features/User/LoginSlice'
import { SocketContext, socket } from './context/Socket'

import '@formatjs/intl-numberformat/polyfill'
import '@formatjs/intl-numberformat/locale-data/vi'

//page
const NotFound = lazy(() => import('./views/NotFound'))

//user
const TokenSell = lazy(() => import('./views/TokenSell'))
const SwapUsdt = lazy(() => import('./views/Swap'))
const SwapCoin = lazy(() => import('./views/SwapCoin'))
// const SwapCoin = lazy(() => import('./views/SwapCoin'))
const Register = lazy(() => import('./views/Register'))
const IDO = lazy(() => import('./views/IDO'))
const Login = lazy(() => import('./views/Login'))
const ForgotPassword = lazy(() => import('./views/ForgotPassword'))
// const Dashboard = lazy(() => import('./views/Dashboard'))
const Tree = lazy(() => import('./views/Tree'))
const MyPackages = lazy(() => import('./views/MyPackages'))
const LogProfitSystem = lazy(() => import('./views/LogProfitSystem'))
const ListPacket = lazy(() => import('./views/ListPacket'))
//const Deposit = lazy(() => import('./views/Deposit'))
const WithDraw = lazy(() => import('./views/Withdraw'))
const LogInterest = lazy(() => import('./views/LogInterest'))
const ShareLink = lazy(() => import('./views/ShareLink'))
// const Profile = lazy(() => import('./views/Profile'))
const LoginPassword = lazy(() => import('./views/Profile/components/LoginPassword'))
const WithdrawPasswrod = lazy(() => import('./views/Profile/components/WithdrawPasswrod'))
const LogCancel = lazy(() => import('./views/LogCancel'))
// const Home = lazy(() => import('./views/Home'))
const Home2 = lazy(() => import('./views/Home/question'))
const Privaty = lazy(() => import('./views/Home/privaty'))
const Privaty2 = lazy(() => import('./views/Home/khac'))
// const Abount = lazy(() => import('./views/Abount'))
const DepositCoin = lazy(() => import('./views/Wallet/Deposit'))
//admin
const AdminUser = lazy(() => import('./views/Admin/User'))
const AdminEditUser = lazy(() => import('./views/Admin/User/components/Edit/User'))
const AdminLogDeposit = lazy(() => import('./views/Admin/Log/Deposit'))
const AdminLogWithdraw = lazy(() => import('./views/Admin/Log/Withdraw'))
const AdminLogDepositPost = lazy(() => import('./views/Admin/Log/DepositPost'))
const AdminBank = lazy(() => import('./views/Admin/Bank'))
const AdminInfor = lazy(() => import('./views/Admin/Info'))
const AdminPacket = lazy(() => import('./views/Admin/Packet'))
const AdminAlert = lazy(() => import('./views/Admin/Alert'))
const AdminSwap = lazy(() => import('./views/Admin/Swap'))

const App = () => {
    const locale = useAppSelector(selectLocal)

    const [mode, setMode] = useState<'light' | 'dark'>('dark')
    const colorMode = useMemo(
        () => ({
            toggleColorMode: () => {
                setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'))
            },
        }),
        []
    )
    let theme = useMemo(
        () =>
            createTheme({
                ...ThemeConfig,
                palette: {
                    mode,
                    background: mode === 'dark' ? { default: 'rgb(39 38 44)', paper: 'rgb(39 38 44)' } : { default: 'rgb(250, 249, 250)', paper: 'rgb(250, 249, 250)' },
                },
            }),
        [mode]
    )
    theme = responsiveFontSizes(theme)

    const { token, role } = useAppSelector(selectLogin)

    useEffect(() => {
        theme.typography.body2 = {
            fontSize: '1rem',
        }
    }, [theme])

    useEffect(() => {
        window.addEventListener(
            'beforeunload',
            (e) => {
                e.preventDefault()
                socket.disconnect()
            },
            false
        )
    }, [])
    const dispatch = useAppDispatch()
    dispatch(changeLocale('vi'))

    // if (localStorage.getItem('language')) dispatch(changeLocale(localStorage.getItem('language')))

    useEffect(() => {
        const tokenTolcal = localStorage.getItem('token')
        if (tokenTolcal) dispatch(LoadingUserAsync(tokenTolcal))
    }, [dispatch])

    return (
        <SocketContext.Provider value={socket}>
            <SnackbarProvider maxSnack={2}>
                <Router history={history}>
                    <IntlProvider locale={locale} messages={chooseLocale(locale)}>
                        <ColorModeContext.Provider value={colorMode}>
                            <ThemeProvider theme={theme}>
                                <CssBaseline />
                                <Page>
                                    <SuspenseWithChunkError fallback={<PageLoader />}>
                                        <Switch>
                                            {/*Start Admin*/}
                                            <Route path="/admin/quan-ly-tai-khoan.html" exact>
                                                {role.admin && token ? <AdminUser /> : <Redirect to="/" />}
                                            </Route>

                                            <Route path="/admin/swap.html" exact>
                                                {role.admin && token ? <AdminSwap /> : <Redirect to="/" />}
                                            </Route>
                                            <Route path="/admin/cai-dat-thong-bao.html" exact>
                                                {role.admin && token ? <AdminAlert /> : <Redirect to="/" />}
                                            </Route>
                                            <Route path="/admin/quan-ly-khach-hang/:_id.html" exact>
                                                {role.admin && token ? <AdminEditUser /> : <Redirect to="/" />}
                                            </Route>
                                            <Route path="/admin/danh-sach-nap-tien.html" exact>
                                                {role.admin && token ? <AdminLogDeposit /> : <Redirect to="/" />}
                                            </Route>
                                            <Route path="/admin/danh-sach-rut-tien.html" exact>
                                                {role.admin && token ? <AdminLogWithdraw /> : <Redirect to="/" />}
                                            </Route>
                                            <Route path="/admin/nhat-ky-yeu-cau-nap-tien.html" exact>
                                                {role.admin && token ? <AdminLogDepositPost /> : <Redirect to="/" />}
                                            </Route>
                                            <Route path="/admin/thong-tin-he-thong.html" exact>
                                                {role.admin && token ? <AdminInfor /> : <Redirect to="/" />}
                                            </Route>
                                            <Route path="/admin/tai-khoan-ngan-hang.html" exact>
                                                {role.admin && token ? <AdminBank /> : <Redirect to="/" />}
                                            </Route>
                                            <Route path="/admin/thay-doi-lai-suat.html" exact>
                                                {role.admin && token ? <AdminPacket /> : <Redirect to="/" />}
                                            </Route>
                                            {/*End Admin*/}
                                            {/*Start User*/}
                                            <Route path="/swap-usdt-coin.html" exact>
                                                {!token ? <Login /> : <SwapUsdt />}
                                            </Route>

                                            <Route path="/swap-coin-usdt.html" exact>
                                                {!token ? <Login /> : <SwapCoin />}
                                            </Route>
                                            <Route path="/buy-coin" exact>
                                                <IDO />
                                            </Route>
                                            <Route path="/" exact>
                                                <Redirect to="/moi-ban-be.html" />
                                                {/* <Home /> */}
                                            </Route>

                                            <Route path="/bang-tich-hop.html" exact>
                                                <Redirect to="/thi-truong.html" />
                                                {/* <Home /> */}
                                            </Route>
                                            <Route path="/mua-token.html" exact>
                                                {!token ? <Login /> : <TokenSell />}
                                            </Route>

                                            <Route path="/ve-chung-toi.html" exact>
                                                <Redirect to="/thi-truong.html" />
                                            </Route>
                                            <Route path="/dieu-khoan-va-dieu-kien.html" exact>
                                                <Privaty2 />
                                            </Route>
                                            <Route path="/chinh-sach-bao-mat.html" exact>
                                                <Privaty />
                                            </Route>

                                            <Route path="/cau-hoi-thuong-gap.html" exact>
                                                <Home2 />
                                            </Route>

                                            <Route path="/thi-truong.html" exact>
                                                {/* <Dashboard /> */}
                                                <Redirect to="/" />
                                            </Route>
                                            <Route path="/dang-ky-tai-khoan.html" exact>
                                                <Register />
                                            </Route>

                                            <Route path="/dang-nhap.html">
                                                <Login />
                                            </Route>

                                            <Route path="/quen-mat-khau.html">
                                                <ForgotPassword />
                                            </Route>

                                            <Route path="/ho-so.html" exact>
                                                <Redirect to="/ho-so-tai-khoan/thay-doi-mat-khau-dang-nhap.html" />
                                            </Route>
                                            <Route path="/cay-he-thong.html" exact>
                                                {!token ? <Login /> : <Tree />}
                                            </Route>

                                            <Route path="/goi-dau-tu-cua-toi.html" exact>
                                                {!token ? <Login /> : <MyPackages />}
                                            </Route>
                                            <Route path="/lich-su-tra-thuong.html" exact>
                                                {!token ? <Login /> : <LogProfitSystem />}
                                            </Route>

                                            <Route path="/nap-tien.html" exact>
                                                <Redirect to="/nap-coin.html" />
                                            </Route>
                                            <Route path="/rut-tien.html" exact>
                                                <Redirect to="/rut-coin.html" />
                                            </Route>

                                            <Route path="/nhat-ky-lai-suat.html" exact>
                                                {!token ? <Login /> : <LogInterest />}
                                            </Route>
                                            <Route path="/danh-sach-goi-dau-tu.html" exact>
                                                {!token ? <Login /> : <ListPacket />}
                                            </Route>
                                            <Route path="/moi-ban-be.html" exact>
                                                {!token ? <Login /> : <ShareLink />}
                                            </Route>

                                            <Route path="/ho-so-tai-khoan.html" exact>
                                                {/* <Redirect to="/ho-so-tai-khoan/ngan-hang.html" /> */}
                                                <Redirect to="/ho-so-tai-khoan/thay-doi-mat-khau-dang-nhap.html" />
                                            </Route>
                                            <Route path="/ho-so-tai-khoan/ngan-hang.html" exact>
                                                {!token ? <Login /> : <LoginPassword />}
                                            </Route>

                                            <Route path="/ho-so-tai-khoan/thay-doi-mat-khau-dang-nhap.html" exact>
                                                {!token ? <Login /> : <LoginPassword />}
                                            </Route>
                                            <Route path="/ho-so-tai-khoan/thay-doi-mat-khau-rut-tien.html" exact>
                                                {!token ? <Login /> : <WithdrawPasswrod />}
                                            </Route>
                                            <Route path="/nhat-ky-huy-goi-dau-tu.html" exact>
                                                {!token ? <Login /> : <LogCancel />}
                                            </Route>
                                            <Route path="/nap-coin.html" exact>
                                                {!token ? <Login /> : <DepositCoin />}
                                            </Route>

                                            <Route path="/rut-coin.html" exact>
                                                {!token ? <Login /> : <WithDraw />}
                                            </Route>
                                            {/*End User*/}

                                            {/*Start Page*/}
                                            <Route component={NotFound} />
                                            {/*End Page*/}
                                        </Switch>
                                    </SuspenseWithChunkError>
                                </Page>
                            </ThemeProvider>
                        </ColorModeContext.Provider>
                    </IntlProvider>
                </Router>
            </SnackbarProvider>
        </SocketContext.Provider>
    )
}

export default App
