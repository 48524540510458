import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { apiPost } from '../../../hooks/api'
import { RootState } from '../../app/store'

interface forgotPasswordState {
    message: string
    status: 'idle' | 'loading' | 'failed' | 'succeeded'
}

interface forgotPasswordProps {
    email: string
    captcha: number | string
}

const initialState: forgotPasswordState = {
    message: '',
    status: 'idle',
}

export const ForgotPasswordAsync = createAsyncThunk('user/forgot-password', async (data: forgotPasswordProps) => {
    return await apiPost({ ...data }, 'user/forgot-password')
})

export const forgotPassordSlice = createSlice({
    name: 'user/forgot-password',
    initialState,
    reducers: {
        clearState: (state) => {
            return { ...state, status: 'idle' }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(ForgotPasswordAsync.pending, (state) => {
                return { ...state, status: 'loading' }
            })
            .addCase(ForgotPasswordAsync.fulfilled, (state) => {
                return { ...state, status: 'succeeded', message: '' }
            })
            .addCase(ForgotPasswordAsync.rejected, (state, { error }) => {
                return { ...state, status: 'failed', message: error.message as string }
            })
    },
})
export const { clearState } = forgotPassordSlice.actions
export const selectForgotPassword = (state: RootState) => state.forgotPassword
export default forgotPassordSlice.reducer
