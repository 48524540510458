import { Typography } from '@mui/material'
import React from 'react'
import NumberFormat from 'react-number-format'
import { SxProps } from '@mui/material'
interface Props extends React.ImgHTMLAttributes<HTMLImageElement> {
    num: number
    sx?: SxProps
    variant: 'body1' | 'body2' | 'h4' | 'h5'
}

const Price: React.FC<Props> = ({ num, sx, variant }) => {
    return (
        <NumberFormat
            prefix={''}
            suffix={' BMG'}
            value={num}
            displayType={'text'}
            thousandSeparator={true}
            renderText={(formattedValue) => (
                <Typography variant={variant} sx={sx}>
                    {formattedValue} - {(num * 1).toFixed(2)}$
                </Typography>
            )}
        />
    )
}

export default Price
