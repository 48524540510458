import React, { useEffect, useState } from 'react'
import Toast from '../component/Toast'
import Backdrop from '../component/Backdrop'
import Page from '../component/Page'
import { useAppDispatch, useAppSelector } from '../states/app/hooks'
import { selectLogin } from '../states/features/User/LoginSlice'
import { eventLoading } from '../states/features/Backdrop/BackdropSlice'
import Menu from '../component/Menu'
import { selectAside } from '../states/features/Aside/AsideSlice'

const PageLayout: React.FC = ({ children }) => {
    const dispatch = useAppDispatch()
    const { status, statusLoading, token } = useAppSelector(selectLogin)
    const { asideName } = useAppSelector(selectAside)
    useEffect(() => {
        token && dispatch(eventLoading(!(status === 'succeeded' || statusLoading === 'succeeded')))
    }, [dispatch, status, statusLoading, token])
    const [openMenuLeft, setOpenMenuLeft] = useState(false)
    useEffect(() => {
        setOpenMenuLeft(false)
    }, [asideName])
    return (
        <>
            {asideName !== 'Home' ? (
                <>
                    <Menu openMenuLeft={openMenuLeft} setOpenMenuLeft={setOpenMenuLeft}>
                        <Page openMenuLeft={openMenuLeft} setOpenMenuLeft={setOpenMenuLeft}>
                            <> {children}</>
                        </Page>
                    </Menu>
                    <Toast />
                    <Backdrop />
                </>
            ) : (
                <>{children}</>
            )}
        </>
    )
}

export default PageLayout
