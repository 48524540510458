import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import useApi from '../../../../hooks/useApi'
import { RootState } from '../../../app/store'
interface packetProps {
    _id: string
    profit: number
    time: number
    max: string
    min: string
    maxNumber: number
    minNumber: number
    day: boolean
}
interface PacketDepositState {
    message: string
    status: 'idle' | 'loading' | 'failed' | 'succeeded'
    statusGet: 'idle' | 'loading' | 'failed' | 'succeeded'
    list: Array<packetProps>
}

interface postPros {
    token: string
    amount: number
    num: number
    client: string
}

interface getPros {
    token: string
}

const initialState: PacketDepositState = {
    message: '',
    status: 'idle',
    statusGet: 'idle',
    list: [],
}

export const PacketDepositAsync = createAsyncThunk('user/packet-depositt/post', async (data: postPros) => {
    const { apiPost } = useApi()
    return await apiPost({ ...data, token: '' }, `user/packet-depositt/post?access_token=${data.token}`)
})
export const PacketDeposiGettAsync = createAsyncThunk('user/packet-depositt/get', async (data: getPros) => {
    const { apiPost } = useApi()
    return await apiPost({ ...data, token: '' }, `user/packet-depositt/get?access_token=${data.token}`)
})

export const PacketDepositSlice = createSlice({
    name: 'user/packet-deposit',
    initialState,
    reducers: {
        clearState: () => initialState,
        setSuccess: (state) => {
            return { ...state, status: 'succeeded', message: 'Đăng ký thành công' }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(PacketDepositAsync.pending, (state) => {
                const newState = { ...initialState, status: 'loading', message: '' }
                return { ...state, ...(newState as PacketDepositState) }
            })
            .addCase(PacketDepositAsync.fulfilled, (state) => {
                const newState = { ...initialState, status: 'loading' }
                return { ...state, ...(newState as PacketDepositState) }
            })
            .addCase(PacketDepositAsync.rejected, (state, { error }) => {
                const newState = { ...initialState, status: 'failed', message: error.message as string }
                return { ...state, ...(newState as PacketDepositState) }
            })

            .addCase(PacketDeposiGettAsync.pending, (state) => {
                const newState = { ...initialState, statusGet: 'loading', message: '' }
                return { ...state, ...(newState as PacketDepositState) }
            })
            .addCase(PacketDeposiGettAsync.fulfilled, (state, { payload }) => {
                const list = payload as Array<packetProps>
                const newState = { ...state, statusGet: 'succeeded', list }
                return { ...state, ...(newState as PacketDepositState) }
            })
            .addCase(PacketDeposiGettAsync.rejected, (state, { error }) => {
                const newState = { ...initialState, statusGet: 'failed', message: error.message as string }
                return { ...state, ...(newState as PacketDepositState) }
            })
    },
})

export const { clearState, setSuccess } = PacketDepositSlice.actions
export const selectPacketDeposit = (state: RootState) => state.packetDeposit
export default PacketDepositSlice.reducer
