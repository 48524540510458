import React from 'react'
import { Box, styled } from '@mui/material'
import { useTranslation } from '../../hooks/Localization'

const StyledBox = styled(Box)`
    padding: ${({ theme }) => theme.spacing(2)};
    flex-grow: 1;
    display: flex;
    margin-top: 70px;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 250px);
    flex-direction: column;
    overflow-y: auto;
    height: 100%;
`

const PageLoader: React.FC = () => {
    const t = useTranslation
    return (
        <StyledBox>
            <Box
                component="img"
                sx={{
                    width: '200px',
                }}
                alt={t('Webname')}
                src="/logo192.png?v=2"
            />
        </StyledBox>
    )
}

export default PageLoader
