import { configureStore, ThunkAction, Action, combineReducers, getDefaultMiddleware } from '@reduxjs/toolkit'
import localReducer from '../features/Locale/LocalSlice'
import registerReducer from '../features/User/RegisterSlice'
import loginReducer from '../features/User/LoginSlice'
import captchaReducer from '../features/Captcha/CaptchaSlice'
import treeReducer from '../features/Tree/TreeSlice'
import asideReducer from '../features/Aside/AsideSlice'
import forgotPasswordReducer from '../features/User/ForgotPassword'
import forgotPasswordCodeReducer from '../features/User/ForgotPasswordCode'
import changePassword1Reducer from '../features/Passsword/Password1Silce'
import toastReducer from '../features/Toast/ToastSlice'
import backdropReducer from '../features/Backdrop/BackdropSlice'
import socketReducer from '../features/Socket/SocketSlice'
import acitiveReducer from '../features/User/ActiveSlice'
import adminGetAllUserReducer from '../features/Admin/User/ShowSlice'
import adminEditUserReducer from '../features/Admin/User/EditUser'
import uploadBankReducer from '../features/User/Upload/BankSlice'
import getLogDepositReducer from '../features/User/Log/DepositSlice'
import withdrawReducer from '../features/User/Withdraw/WithdrawSlice'
import getLogWithdrawReducer from '../features/User/Log/WithdrawSlice'
import adminGetLogDepositReducer from '../features/Admin/Log/DepositSlice'
import adminGetLogWithdrawReducer from '../features/Admin/Log/WithdrawSlice'
import changePassword2Reducer from '../features/Passsword/Password2Slice'
import PacketDepositReducer from '../features/User/Packet/PacketSlice'
import getLogPacketReducer from '../features/User/Log/GetPacketSlice'
import depositPostReducer from '../features/User/Deposit/DepositSlice'
import adminBankReducer from '../features/Admin/Bank/BankSlice'
import adminGetInfo from '../features/Admin/Infor/InforSlice'
import adminPacket from '../features/Admin/Packet/PacketSlice'
import adminAlert from '../features/Admin/Alert/AlertSlice'
import AddressReducer from '../features/Wallet/AddressSlice'
import SwaoCoinReducer from '../features/User/SwapCoin/SwapCoinSlice'
import LogSwapReducer from '../features/User/Log/SwapSlice'
import AdminLogSwapReducer from '../features/Admin/Log/SwapSlice'

const combinedReducer = combineReducers({
    locale: localReducer,
    AdminLogSwapReducer,
    LogSwapReducer,
    register: registerReducer,
    login: loginReducer,
    captcha: captchaReducer,
    tree: treeReducer,
    aisde: asideReducer,
    forgotPassword: forgotPasswordReducer,
    forgotPasswordCode: forgotPasswordCodeReducer,
    changePassword1: changePassword1Reducer,
    changePassword2: changePassword2Reducer,
    toast: toastReducer,
    backdrop: backdropReducer,
    socket: socketReducer,
    active: acitiveReducer,
    adminGetAllUser: adminGetAllUserReducer,
    adminEditUser: adminEditUserReducer,
    uploadBank: uploadBankReducer,
    getLogDeposit: getLogDepositReducer,
    withdraw: withdrawReducer,
    getLogWithdraw: getLogWithdrawReducer,
    adminGetLogDeposit: adminGetLogDepositReducer,
    adminGetLogWithdraw: adminGetLogWithdrawReducer,
    packetDeposit: PacketDepositReducer,
    getLogPacket: getLogPacketReducer,
    depositPost: depositPostReducer,
    adminBank: adminBankReducer,
    swapCoin: SwaoCoinReducer,
    adminGetInfo,
    adminPacket,
    adminAlert,
    address: AddressReducer,
})

const rootReducer = (state: any, action: any) => {
    if (action.type === 'user/loign/clearState' || action.type === 'user/login/loading/rejected') {
        localStorage.removeItem('token')
        window.location.reload()
    }
    return combinedReducer(state, action)
}
export const store = configureStore({
    reducer: rootReducer,
    middleware: [...getDefaultMiddleware()],
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>
