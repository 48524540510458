import React from 'react'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import Divider from '@mui/material/Divider'
import UserMenuLeft from './UserMenuLeft'

const drawerWidth = 300

interface Props {
    children: React.ReactElement
    openMenuLeft: boolean
    setOpenMenuLeft: React.Dispatch<React.SetStateAction<boolean>>
}
const Menu: React.FC<Props> = ({ children, openMenuLeft, setOpenMenuLeft }) => {
    return (
        <Box sx={{ display: 'flex', minHeight: '100%', width: '100%' }}>
            <Drawer
                sx={{
                    width: drawerWidth,
                    display: { xs: 'none', md: 'block' },
                    '& .MuiDrawer-paper': {
                        width: { xs: 200, sm: drawerWidth },
                        boxSizing: 'border-box',
                        borderRight: (theme) => (theme.palette.mode === 'dark' ? '1px solid rgba(255, 255, 255, 0.12)' : '1px solid rgba(0, 0, 0, 0.12)'),
                    },
                }}
                variant="permanent"
                onClose={() => setOpenMenuLeft(!openMenuLeft)}
                anchor="left"
                open={openMenuLeft}
                ModalProps={{
                    keepMounted: true,
                }}
            >
                <Divider />
                <UserMenuLeft setOpenMenuLeft={setOpenMenuLeft} />
            </Drawer>
            <Drawer
                sx={{
                    display: { xs: 'block', md: 'none' },
                    '& .MuiDrawer-paper': {
                        width: { xs: '270px', sm: '350px' },
                        boxSizing: 'border-box',
                        borderRight: (theme) => (theme.palette.mode === 'dark' ? '1px solid rgba(255, 255, 255, 0.12)' : '1px solid rgba(0, 0, 0, 0.12)'),
                    },
                }}
                variant="temporary"
                onClose={() => setOpenMenuLeft((prev) => !prev)}
                anchor="left"
                open={openMenuLeft}
                ModalProps={{
                    keepMounted: true,
                }}
            >
                <Divider />
                <UserMenuLeft setOpenMenuLeft={setOpenMenuLeft} />
            </Drawer>
            <Box component="main" sx={{ width: '100%', minHeight: '100%', flexGrow: 1 }}>
                {children}
            </Box>
        </Box>
    )
}

export default Menu
