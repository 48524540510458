import React, { useCallback } from 'react'
import { Snackbar, Alert, AlertTitle, Slide, SlideProps } from '@mui/material'
import { useTranslation } from '../../hooks/Localization'
import { useAppDispatch, useAppSelector } from '../../states/app/hooks'
import { eventShow, selectToast } from '../../states/features/Toast/ToastSlice'
type TransitionProps = Omit<SlideProps, 'direction'>
const TransitionUp = (props: TransitionProps) => {
    return <Slide {...props} direction="right" />
}

const Toast: React.FC = () => {
    const t = useTranslation
    const dispatch = useAppDispatch()
    const { show, message, severity } = useAppSelector(selectToast)
    const closeToast = useCallback(
        (event?: React.SyntheticEvent | Event, reason?: string) => {
            reason !== 'clickaway' && dispatch(eventShow(false))
        },
        [dispatch]
    )

    return (
        <Snackbar
            sx={{ zIndex: 99999999 }}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={show}
            autoHideDuration={3000}
            onClose={closeToast}
            TransitionComponent={TransitionUp}
        >
            <Alert onClose={closeToast} severity={severity} sx={{ width: '100%' }}>
                <AlertTitle>{severity === 'error' ? t('Error') : severity === 'warning' ? 'Đang chờ' : t('Success')}</AlertTitle>
                {t('Webname')} — <strong>{message}</strong>
            </Alert>
        </Snackbar>
    )
}
export default Toast
