const API = process.env.REACT_APP_PUBLIC_API
export const apiPost = async (body: object, action: string = '') => {
    const response = await fetch(`${API + '/' + action}`, {
        method: 'POST',
        credentials: 'include',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    })
    const data = await response.json()

    return new Promise((resolve, reject) => {
        if (response.status !== 200) {
            reject(data)
        } else {
            resolve(data)
        }
    })
}

export const apiGet = async (action: string = '') => {
    const response = await fetch(`${API + '/' + action}`, {
        method: 'GET',
    })
    const data = await response.json()
    return new Promise((resolve, reject) => {
        if (response.status !== 200) {
            reject(data)
        } else {
            resolve(data)
        }
    })
}
