import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import useApi from '../../../../hooks/useApi'
import { RootState } from '../../../app/store'

interface typeAmoutProps {
    type: boolean
    before: number
    after: number
}
interface withdrawConfrimProp {
    success: number
}
export interface itemProps {
    _id: string
    value: number
    note: string
    address: string
    createdAt?: Date
    typeAmount: typeAmoutProps
    swap: withdrawConfrimProp
    vnd: string
    username: string
}

interface getLogWithdrawState {
    items: Array<itemProps>
    message: string
    status: 'idle' | 'loading' | 'failed' | 'sucessded'
    statusSet: 'idle' | 'loading' | 'failed' | 'sucessded'
}

const initialState: getLogWithdrawState = {
    items: [],
    message: '',
    status: 'idle',
    statusSet: 'idle',
}
interface getProps {
    token: string
    page: number
}

interface setProps {
    token: string
    _id: string
}
export const getLogWithdrawAsync = createAsyncThunk('admin/logs/swapcoin/get', async (data: getProps) => {
    const { apiGet } = useApi()
    const { token, page } = data
    const tmp = await apiGet(`admin/get-log-swap/${page}?access_token=${token}`)
    return tmp
})

export const setLogWithdrawAsync = createAsyncThunk('admin/logs/swapcoin/set', async (data: setProps) => {
    const { apiGet } = useApi()
    const { token, _id } = data
    const tmp = await apiGet(`admin/set-log-swap/${_id}?access_token=${token}`)
    return tmp
})

export const getLogWithdrawSlice = createSlice({
    name: 'admin/logs/swapcoin',
    initialState,
    reducers: {
        clearLogWithdrawState: (state) => {
            return {
                ...state,
                status: 'idle',
                message: '',
                item: initialState.items,
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getLogWithdrawAsync.fulfilled, (state, { payload }) => {
                const { items } = payload as getLogWithdrawState
                return {
                    ...state,
                    status: 'sucessded',
                    items,
                    message: '',
                }
            })
            .addCase(getLogWithdrawAsync.rejected, (state, { error }) => {
                return {
                    ...state,
                    status: 'failed',
                    message: error.message as string,
                    item: initialState.items,
                }
            })
            .addCase(getLogWithdrawAsync.pending, (state) => {
                return {
                    ...state,
                    status: 'loading',
                    items: initialState.items,
                    message: '',
                }
            })

            .addCase(setLogWithdrawAsync.fulfilled, (state, { payload }) => {
                const { items } = payload as getLogWithdrawState
                return {
                    ...state,
                    statusSet: 'sucessded',
                    items,
                    message: '',
                }
            })
            .addCase(setLogWithdrawAsync.rejected, (state, { error }) => {
                return {
                    ...state,
                    statusSet: 'failed',
                    message: error.message as string,
                    item: initialState.items,
                }
            })
            .addCase(setLogWithdrawAsync.pending, (state) => {
                return {
                    ...state,
                    statusSet: 'loading',
                    items: initialState.items,
                    message: '',
                }
            })
    },
})

export const { clearLogWithdrawState } = getLogWithdrawSlice.actions
export const selectLogWithdraw = (state: RootState) => state.AdminLogSwapReducer
export default getLogWithdrawSlice.reducer
