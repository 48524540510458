import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { apiPost } from '../../../hooks/api'
import { RootState } from '../../app/store'

interface password2State {
    message: string
    status: 'idle' | 'loading' | 'failed' | 'succeeded'
}

interface userChangePasswrd2State {
    oldPassword: string
    newPassword: string
    confNewPassword: string
    token: string
}

const initialState: password2State = {
    message: '',
    status: 'idle',
}

export const ChangePassword2Async = createAsyncThunk('user/change-password2', async (data: userChangePasswrd2State) => {
    return await apiPost({ ...data, token: '' }, `user/change-password2?access_token=${data.token}`)
})

export const Password2Slice = createSlice({
    name: 'user/change-password2',
    initialState,
    reducers: {
        clearState: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(ChangePassword2Async.pending, (state) => {
                return { ...state, status: 'loading' }
            })
            .addCase(ChangePassword2Async.fulfilled, (state) => {
                return { ...state, status: 'succeeded', message: 'Thay đổi mật khẩu rút tiền thành công' }
            })
            .addCase(ChangePassword2Async.rejected, (state, { error }) => {
                return { ...state, status: 'failed', message: error.message as string }
            })
    },
})
export const { clearState } = Password2Slice.actions
export const selectPassword2 = (state: RootState) => state.changePassword2
export default Password2Slice.reducer
