import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import useApi from '../../../../hooks/useApi'
import { RootState } from '../../../app/store'

interface typeAmoutProps {
    type: boolean
    before: number
    after: number
}

interface packetProps {
    time: Date
    day: number
    onReturn: number
    cancel: number
}

export interface itemProps {
    value: number
    note: string
    createdAt?: Date
    typeAmount: typeAmoutProps
    packet: packetProps
    _id: string
}

interface getLogPacketState {
    items: Array<itemProps>
    sum: number
    message: string
    status: 'idle' | 'loading' | 'failed' | 'sucessded'
    statusCancel: 'idle' | 'loading' | 'failed' | 'sucessded'
    statusLogCancel: 'idle' | 'loading' | 'failed' | 'sucessded'
    statusLogProfit: 'idle' | 'loading' | 'failed' | 'sucessded'
    statusLogProfitSystem: 'idle' | 'loading' | 'failed' | 'sucessded'
}

const initialState: getLogPacketState = {
    items: [],
    sum: 0,
    message: '',
    status: 'idle',
    statusCancel: 'idle',
    statusLogCancel: 'idle',
    statusLogProfit: 'idle',
    statusLogProfitSystem: 'idle',
}
interface getProps {
    token: string
    page: number
}

interface postProps {
    token: string
    _id: string
    client: string
}
export const getLogPacketAsync = createAsyncThunk('user/logs/packet/get', async (data: getProps) => {
    const { apiGet } = useApi()
    const { token, page } = data
    return await apiGet(`user/get-log-packet/${page}?access_token=${token}`)
})

export const getLogPacketCancelAsync = createAsyncThunk('user/logs/packet/log-cancel', async (data: getProps) => {
    const { apiGet } = useApi()
    const { token, page } = data
    return await apiGet(`user/get-log-packet-cancel/${page}?access_token=${token}`)
})

export const getLogPacketProfitAsync = createAsyncThunk('user/logs/packet/log-profit', async (data: getProps) => {
    const { apiGet } = useApi()
    const { token, page } = data
    return await apiGet(`user/get-log-packet-profit/${page}?access_token=${token}`)
})

export const getLogPacketProfitSystemAsync = createAsyncThunk('user/logs/packet/log-profit-system', async (data: getProps) => {
    const { apiGet } = useApi()
    const { token, page } = data
    return await apiGet(`user/get-log-packet-profit-system/${page}?access_token=${token}`)
})

export const cancelLogPacketAsync = createAsyncThunk('user/logs/packet/cancel', async (data: postProps) => {
    const { apiPost } = useApi()
    return await apiPost({ ...data, token: '' }, `user/packet-cancel?access_token=${data.token}`)
})
export const getLogPacketSlice = createSlice({
    name: 'user/logs/packet',
    initialState,
    reducers: {
        clearLogPacketState: () => initialState,
        setSuccess: (state) => {
            return { ...state, statusCancel: 'sucessded', message: '' }
        },
    },
    extraReducers: (builder) => {
        builder
            //--------------------------------------------------------------------------------------
            .addCase(getLogPacketAsync.fulfilled, (state, { payload }) => {
                const { items } = payload as getLogPacketState
                return {
                    ...state,
                    status: 'sucessded',
                    items,
                    message: '',
                }
            })
            .addCase(getLogPacketAsync.rejected, (state, { error }) => {
                return {
                    ...state,
                    status: 'failed',
                    message: error.message as string,
                    item: initialState.items,
                }
            })
            .addCase(getLogPacketAsync.pending, (state) => {
                return {
                    ...state,
                    status: 'loading',
                    items: initialState.items,
                    message: '',
                }
            })

            //--------------------------------------------------------------------------------------
            .addCase(cancelLogPacketAsync.fulfilled, (state) => {
                return {
                    ...state,
                    statusCancel: 'loading',
                    message: '',
                }
            })
            .addCase(cancelLogPacketAsync.rejected, (state, { error }) => {
                return {
                    ...state,
                    statusCancel: 'failed',
                    message: error.message as string,
                }
            })
            .addCase(cancelLogPacketAsync.pending, (state) => {
                return {
                    ...state,
                    statusCancel: 'loading',
                    message: '',
                }
            })

            //--------------------------------------------------------------------------------------
            .addCase(getLogPacketCancelAsync.fulfilled, (state, { payload }) => {
                const { items } = payload as getLogPacketState
                return {
                    ...state,
                    statusLogCancel: 'sucessded',
                    items,
                    message: '',
                }
            })
            .addCase(getLogPacketCancelAsync.rejected, (state, { error }) => {
                return {
                    ...state,
                    statusLogCancel: 'failed',
                    message: error.message as string,
                    item: initialState.items,
                }
            })
            .addCase(getLogPacketCancelAsync.pending, (state) => {
                return {
                    ...state,
                    statusLogCancel: 'loading',
                    items: initialState.items,
                    message: '',
                }
            })

            //--------------------------------------------------------------------------------------
            .addCase(getLogPacketProfitAsync.fulfilled, (state, { payload }) => {
                const { items, sum } = payload as getLogPacketState
                return {
                    ...state,
                    statusLogProfit: 'sucessded',
                    items,
                    sum,
                    message: '',
                }
            })
            .addCase(getLogPacketProfitAsync.rejected, (state, { error }) => {
                return {
                    ...state,
                    statusLogProfit: 'failed',
                    message: error.message as string,
                    item: initialState.items,
                }
            })
            .addCase(getLogPacketProfitAsync.pending, (state) => {
                return {
                    ...state,
                    statusLogProfit: 'loading',
                    items: initialState.items,
                    message: '',
                }
            })
            //--------------------------------------------------------------------------------------
            .addCase(getLogPacketProfitSystemAsync.fulfilled, (state, { payload }) => {
                const { items, sum } = payload as getLogPacketState
                return {
                    ...state,
                    statusLogProfitSystem: 'sucessded',
                    items,
                    sum,
                    message: '',
                }
            })
            .addCase(getLogPacketProfitSystemAsync.rejected, (state, { error }) => {
                return {
                    ...state,
                    statusLogProfitSystem: 'failed',
                    message: error.message as string,
                    item: initialState.items,
                }
            })
            .addCase(getLogPacketProfitSystemAsync.pending, (state) => {
                return {
                    ...state,
                    statusLogProfitSystem: 'loading',
                    items: initialState.items,
                    message: '',
                }
            })
    },
})

export const { clearLogPacketState, setSuccess } = getLogPacketSlice.actions
export const selectLogPacket = (state: RootState) => state.getLogPacket
export default getLogPacketSlice.reducer
