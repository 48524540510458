import React from 'react'
import Box from '@mui/system/Box'
import { SxProps } from '@mui/material'
interface ImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
    sx?: SxProps
}
const LazyImage = ({ src, sx, ...props }: ImageProps) => {
    return <Box component="img" {...props} src={src} sx={{ ...sx, maxHeight: '300px', maxWidth: '300px' }} />
}

export default LazyImage
