import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import useApi from '../../../../hooks/useApi'
import { RootState } from '../../../app/store'
import { loginState, initialState as stateLogin } from '../../User/LoginSlice'

interface GetAdminUserById {
    item: loginState
    message: string
    status: 'idle' | 'loading' | 'failed' | 'sucessded'
    statusUpdateBalance: 'idle' | 'loading' | 'failed' | 'sucessded'
    statusUpdateTxn: 'idle' | 'loading' | 'failed' | 'sucessded'
    statusUpdatePassword: 'idle' | 'loading' | 'failed' | 'sucessded'
    statusUpdatePassword2: 'idle' | 'loading' | 'failed' | 'sucessded'
    statusDeletePacket: 'idle' | 'loading' | 'failed' | 'sucessded'
    statusDeleteBank: 'idle' | 'loading' | 'failed' | 'sucessded'
}

const initialState: GetAdminUserById = {
    item: stateLogin,
    message: '',
    status: 'idle',
    statusUpdateTxn: 'idle',
    statusUpdateBalance: 'idle',
    statusUpdatePassword: 'idle',
    statusUpdatePassword2: 'idle',
    statusDeletePacket: 'idle',
    statusDeleteBank: 'idle',
}
interface getProps {
    token: string
    _id: string
}

interface postBalanceProps {
    token: string
    balance: number
    _id: string
}

interface postPasswordProps {
    token: string
    password: string
    _id: string
}
interface postDeletePacketProps {
    token: string
    _id: string
}
interface postDeleteBankProps {
    token: string
    _id: string
    style: boolean
}

export const adminGeUserByIdAsync = createAsyncThunk('admin/user/edit-user/by-id', async (data: getProps) => {
    const { token, _id } = data
    const { apiGet } = useApi()
    return await apiGet(`admin/user/find-user-by-id/${_id}?access_token=${token}`)
})

export const adminUpdateBalanceByIdAsync = createAsyncThunk('admin/user/edit-user/update-balance-by-id', async (data: postBalanceProps) => {
    const { apiPost } = useApi()
    return await apiPost({ ...data, token: '' }, `admin/edit-user/update-balance-by-id?access_token=${data.token}`)
})

export const adminUpdateTxnByIdAsync = createAsyncThunk('admin/user/edit-user/update-txn-by-id', async (data: postBalanceProps) => {
    const { apiPost } = useApi()
    return await apiPost({ ...data, token: '' }, `admin/edit-user/update-txn-by-id?access_token=${data.token}`)
})

export const adminUpdatePasswordByIdAsync = createAsyncThunk('admin/user/edit-user/update-password-by-id', async (data: postPasswordProps) => {
    const { apiPost } = useApi()
    return await apiPost({ ...data, token: '' }, `admin/edit-user/update-password-by-id?access_token=${data.token}`)
})

export const adminUpdatePassword2ByIdAsync = createAsyncThunk('admin/user/edit-user/update-password2-by-id', async (data: postPasswordProps) => {
    const { apiPost } = useApi()
    return await apiPost({ ...data, token: '' }, `admin/edit-user/update-password2-by-id?access_token=${data.token}`)
})

export const adminDeletePacketUByIdAsync = createAsyncThunk('admin/user/edit-user/delete-packet-by-id', async (data: postDeletePacketProps) => {
    const { apiPost } = useApi()
    return await apiPost({ ...data, token: '' }, `admin/edit-user/delete-packet-by-id?access_token=${data.token}`)
})

export const adminDeleteBankByIdAsync = createAsyncThunk('admin/user/edit-user/delete-bank-by-id', async (data: postDeleteBankProps) => {
    const { apiPost } = useApi()
    return await apiPost({ ...data, token: '' }, `admin/edit-user/delete-bank-by-id?access_token=${data.token}`)
})

export const adminEditUserByIdSlice = createSlice({
    name: 'admin/user/edit-user',
    initialState,
    reducers: {
        clearState: (state) => {
            return {
                ...state,
                status: 'idle',
                statusUpdateBalance: 'idle',
                statusUpdateTxn: 'idle',
                statusUpdatePassword: 'idle',
                statusDeletePacket: 'idle',
                message: '',
            }
        },
    },
    extraReducers: (builder) => {
        builder
            //Get info --------------------------------------------
            .addCase(adminGeUserByIdAsync.fulfilled, (state, { payload }) => {
                const { item } = payload as GetAdminUserById
                return {
                    ...state,
                    status: 'sucessded',
                    item,
                    message: '',
                }
            })
            .addCase(adminGeUserByIdAsync.rejected, (state, { error }) => {
                return {
                    ...state,
                    status: 'failed',
                    message: error.message as string,
                    item: initialState.item,
                }
            })
            .addCase(adminGeUserByIdAsync.pending, (state) => {
                return {
                    ...state,
                    status: 'loading',
                    item: initialState.item,
                    message: '',
                }
            })
            //Update phone user--------------------------------------------
            .addCase(adminUpdateTxnByIdAsync.fulfilled, (state, { payload }) => {
                const { item } = payload as GetAdminUserById
                return {
                    ...state,
                    statusUpdateTxn: 'sucessded',
                    item,
                    message: '',
                }
            })
            .addCase(adminUpdateTxnByIdAsync.rejected, (state, { error }) => {
                return {
                    ...state,
                    statusUpdateTxn: 'failed',
                    message: error.message as string,
                }
            })
            .addCase(adminUpdateTxnByIdAsync.pending, (state) => {
                return {
                    ...state,
                    statusUpdateTxn: 'loading',
                    message: '',
                }
            })

            //update txn _________--------------------------------------------
            .addCase(adminUpdateBalanceByIdAsync.fulfilled, (state, { payload }) => {
                const { item } = payload as GetAdminUserById
                return {
                    ...state,
                    statusUpdateBalance: 'sucessded',
                    item,
                    message: '',
                }
            })
            .addCase(adminUpdateBalanceByIdAsync.rejected, (state, { error }) => {
                return {
                    ...state,
                    statusUpdateBalance: 'failed',
                    message: error.message as string,
                }
            })
            .addCase(adminUpdateBalanceByIdAsync.pending, (state) => {
                return {
                    ...state,
                    statusUpdateBalance: 'loading',
                    message: '',
                }
            })

            //Update password user--------------------------------------------
            .addCase(adminUpdatePasswordByIdAsync.fulfilled, (state) => {
                return {
                    ...state,
                    statusUpdatePassword: 'sucessded',
                    message: '',
                }
            })
            .addCase(adminUpdatePasswordByIdAsync.rejected, (state, { error }) => {
                return {
                    ...state,
                    statusUpdatePassword: 'failed',
                    message: error.message as string,
                }
            })
            .addCase(adminUpdatePasswordByIdAsync.pending, (state) => {
                return {
                    ...state,
                    statusUpdatePassword: 'loading',
                    message: '',
                }
            })

            //Update password2 user--------------------------------------------
            .addCase(adminUpdatePassword2ByIdAsync.fulfilled, (state) => {
                return {
                    ...state,
                    statusUpdatePassword2: 'sucessded',
                    message: '',
                }
            })
            .addCase(adminUpdatePassword2ByIdAsync.rejected, (state, { error }) => {
                return {
                    ...state,
                    statusUpdatePassword2: 'failed',
                    message: error.message as string,
                }
            })
            .addCase(adminUpdatePassword2ByIdAsync.pending, (state) => {
                return {
                    ...state,
                    statusUpdatePassword2: 'loading',
                    message: '',
                }
            })

            //Update delete packet user--------------------------------------------
            .addCase(adminDeletePacketUByIdAsync.fulfilled, (state) => {
                return {
                    ...state,
                    statusDeletePacket: 'sucessded',
                    message: '',
                }
            })
            .addCase(adminDeletePacketUByIdAsync.rejected, (state, { error }) => {
                return {
                    ...state,
                    statusDeletePacket: 'failed',
                    message: error.message as string,
                }
            })
            .addCase(adminDeletePacketUByIdAsync.pending, (state) => {
                return {
                    ...state,
                    statusDeletePacket: 'loading',
                    message: '',
                }
            })

            //Update delete bank user--------------------------------------------
            .addCase(adminDeleteBankByIdAsync.fulfilled, (state) => {
                return {
                    ...state,
                    statusDeleteBank: 'sucessded',
                    message: '',
                }
            })
            .addCase(adminDeleteBankByIdAsync.rejected, (state, { error }) => {
                return {
                    ...state,
                    statusDeleteBank: 'failed',
                    message: error.message as string,
                }
            })
            .addCase(adminDeleteBankByIdAsync.pending, (state) => {
                return {
                    ...state,
                    statusDeleteBank: 'loading',
                    message: '',
                }
            })
    },
})

export const { clearState } = adminEditUserByIdSlice.actions
export const selectAdminEditUserById = (state: RootState) => state.adminEditUser
export default adminEditUserByIdSlice.reducer
