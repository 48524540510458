import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import useApi from '../../../../hooks/useApi'
import { RootState } from '../../../app/store'

interface alertItemsState {
    label: string
    description: string
    show: boolean
    status: 'idle' | 'loading' | 'failed' | 'sucessded'
    statusEdit: 'idle' | 'loading' | 'failed' | 'sucessded'
    message: string
}

const initialState: alertItemsState = {
    label: '',
    description: '',
    show: false,
    status: 'idle',
    statusEdit: 'idle',
    message: '',
}
interface getProps {
    token: string
}

interface editPost {
    token: string
    label: string
    description: string
    show: boolean
}

export const adminGeAlertAsync = createAsyncThunk('admin/alert/get', async (data: getProps) => {
    const { token } = data
    const { apiGet } = useApi()
    return await apiGet(`admin/get-alert?access_token=${token}`)
})

export const adminUpdatealertAsync = createAsyncThunk('admin/alert/edit', async (data: editPost) => {
    const { apiPost } = useApi()
    return await apiPost({ ...data, token: '' }, `admin/alert/edit?access_token=${data.token}`)
})

export const adminAlertSlice = createSlice({
    name: 'admin/alert',
    initialState,
    reducers: {
        clearState: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            //Get info --------------------------------------------
            .addCase(adminGeAlertAsync.fulfilled, (state, { payload }) => {
                const { label, description, show } = payload as alertItemsState
                return {
                    ...state,
                    status: 'sucessded',
                    label,
                    description,
                    show,
                }
            })
            .addCase(adminGeAlertAsync.rejected, (state, { error }) => {
                return {
                    ...state,
                    status: 'failed',
                    message: error.message as string,
                    label: '',
                    description: '',
                    show: false,
                }
            })
            .addCase(adminGeAlertAsync.pending, (state) => {
                return {
                    ...state,
                    status: 'loading',
                    label: '',
                    description: '',
                    show: false,
                }
            })

            .addCase(adminUpdatealertAsync.fulfilled, (state, { payload }) => {
                const { label, description, show } = payload as alertItemsState
                return {
                    ...state,
                    statusEdit: 'sucessded',
                    message: 'Cập nhập thành công',
                    label,
                    description,
                    show,
                }
            })
            .addCase(adminUpdatealertAsync.rejected, (state, { error }) => {
                return {
                    ...state,
                    statusEdit: 'failed',
                    message: error.message as string,
                }
            })
            .addCase(adminUpdatealertAsync.pending, (state) => {
                return {
                    ...state,
                    statusEdit: 'loading',
                    message: '',
                }
            })
    },
})

export const { clearState } = adminAlertSlice.actions
export const selectAdminAlert = (state: RootState) => state.adminAlert
export default adminAlertSlice.reducer
