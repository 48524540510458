import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import useApi from '../../../../hooks/useApi'
import { RootState } from '../../../app/store'

interface DepositState {
    message: string
    status: 'idle' | 'loading' | 'failed' | 'succeeded'
}

interface postPros {
    token: string
    amount: number
}

interface postTxn {
    token: string
    txn: string
}

const initialState: DepositState = {
    message: '',
    status: 'idle',
}

export const DepositPostAsync = createAsyncThunk('user/deposit/submit', async (data: postPros) => {
    const { apiPost } = useApi()
    return await apiPost({ ...data, token: '' }, `user/deposit/submit?access_token=${data.token}`)
})

export const TxnPostAsync = createAsyncThunk('user/deposit/txn', async (data: postTxn) => {
    const { apiPost } = useApi()
    return await apiPost({ ...data, token: '' }, `user/deposit/txn?access_token=${data.token}`)
})
export const DepositSlice = createSlice({
    name: 'user/deposit',
    initialState,
    reducers: {
        clearState: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(DepositPostAsync.pending, (state) => {
                const newState = { ...initialState, status: 'loading', message: '' }
                return { ...state, ...(newState as DepositState) }
            })
            .addCase(DepositPostAsync.fulfilled, (state, { payload }) => {
                const newState = { ...initialState, status: 'succeeded', message: payload as string }
                return { ...state, ...(newState as DepositState) }
            })
            .addCase(DepositPostAsync.rejected, (state, { error }) => {
                const newState = { ...initialState, status: 'failed', message: error.message as string }
                return { ...state, ...(newState as DepositState) }
            })

            .addCase(TxnPostAsync.pending, (state) => {
                const newState = { ...initialState, status: 'loading', message: '' }
                return { ...state, ...(newState as DepositState) }
            })
            .addCase(TxnPostAsync.fulfilled, (state, { payload }) => {
                const newState = { ...initialState, status: 'succeeded', message: payload as string }
                return { ...state, ...(newState as DepositState) }
            })
            .addCase(TxnPostAsync.rejected, (state, { error }) => {
                const newState = { ...initialState, status: 'failed', message: error.message as string }
                return { ...state, ...(newState as DepositState) }
            })
    },
})

export const { clearState } = DepositSlice.actions
export const selectDepositPost = (state: RootState) => state.depositPost
export default DepositSlice.reducer
