import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import useApi from '../../../../hooks/useApi'
import { RootState } from '../../../app/store'

interface AdminPacketState {
    status: 'idle' | 'loading' | 'failed' | 'sucessded'
    messageEdit: string
}

const initialState: AdminPacketState = {
    status: 'idle',
    messageEdit: '',
}

interface editPost {
    token: string
    _id: string
    amount: number
    day: number
    min: number
    max: number
}

export const adminUpdatePacketAsync = createAsyncThunk('admin/packet/edit/post', async (data: editPost) => {
    const { apiPost } = useApi()
    return await apiPost({ ...data, token: '' }, `admin/packet/edit?access_token=${data.token}`)
})

export const adminPkacetSlice = createSlice({
    name: 'admin/packet/edit',
    initialState,
    reducers: {
        clearState: () => initialState,
    },
    extraReducers: (builder) => {
        builder

            .addCase(adminUpdatePacketAsync.fulfilled, (state) => {
                return {
                    ...state,
                    status: 'sucessded',
                    messageEdit: 'Cập nhập thành công',
                }
            })
            .addCase(adminUpdatePacketAsync.rejected, (state, { error }) => {
                return {
                    ...state,
                    status: 'failed',
                    messageEdit: error.message as string,
                }
            })
            .addCase(adminUpdatePacketAsync.pending, (state) => {
                return {
                    ...state,
                    status: 'loading',
                    messageEdit: '',
                }
            })
    },
})

export const { clearState } = adminPkacetSlice.actions
export const selectAdminPacket = (state: RootState) => state.adminPacket
export default adminPkacetSlice.reducer
