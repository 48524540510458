import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import { store } from './states/app/store'
import { Provider } from 'react-redux'
import { WagmiConfig, createConfig } from 'wagmi'
import { bsc } from '@wagmi/core/chains'
import { InjectedConnector } from 'wagmi/connectors/injected'
import { createPublicClient, http } from 'viem'
//import { Web3Button, Web3Modal } from '@web3modal/react'
//import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum'

// import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet'
// import { MetaMaskConnector } from 'wagmi/connectors/metaMask'

const chains = [bsc]

const config = createConfig({
    autoConnect: false,
    connectors: [
        new InjectedConnector({
            chains,
            options: {
                name: 'Kết nối ví',
                shimDisconnect: false,
            },
        }),
    ],
    publicClient: createPublicClient({
        chain: bsc,
        transport: http(),
    }),
})

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <WagmiConfig config={config}>
                <App />
            </WagmiConfig>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
)
