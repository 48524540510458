import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { apiPost } from '../../../hooks/api'
import { RootState } from '../../app/store'

interface forgotPasswordCodeState {
    message: string
    status: 'idle' | 'loading' | 'failed' | 'succeeded'
}

interface forgotPasswordCodeProps {
    email: string
    code: number | string
    captcha: number | string
    password: string
}

const initialState: forgotPasswordCodeState = {
    message: '',
    status: 'idle',
}

export const ForgotPasswordCodeAsync = createAsyncThunk('user/change-password-with-code', async (data: forgotPasswordCodeProps) => {
    return await apiPost({ ...data }, 'user/change-password-with-code')
})

export const forgotPassordCodeSlice = createSlice({
    name: 'user/change-password-with-code',
    initialState,
    reducers: {
        clearState: (state) => {
            return { ...state, status: 'idle' }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(ForgotPasswordCodeAsync.pending, (state) => {
                return { ...state, status: 'loading' }
            })
            .addCase(ForgotPasswordCodeAsync.fulfilled, (state) => {
                return { ...state, status: 'succeeded', message: '' }
            })
            .addCase(ForgotPasswordCodeAsync.rejected, (state, { error }) => {
                return { ...state, status: 'failed', message: error.message as string }
            })
    },
})
export const { clearState } = forgotPassordCodeSlice.actions
export const selectForgotPasswordCode = (state: RootState) => state.forgotPasswordCode
export default forgotPassordCodeSlice.reducer
