import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { apiPost } from '../../../hooks/api'
import { RootState } from '../../app/store'

interface registerState {
    messages: string
    username: string
    phone: string
    status: 'idle' | 'loading' | 'failed' | 'succeeded'
}

interface userRegisterState {
    phone: string
    password1: string
    conf_password1: string
    password2: string
    refParent?: string
    username: string
}

const initialState: registerState = {
    messages: '',
    username: '',
    phone: '',
    status: 'idle',
}

export const RegisterAsync = createAsyncThunk('user/register', async (data: userRegisterState) => {
    return await apiPost({ ...data }, 'user/sign-up')
})

export const registerSlice = createSlice({
    name: 'user/register',
    initialState,
    reducers: {
        clearState: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(RegisterAsync.pending, (state) => {
                const newState = { ...initialState, status: 'loading', message: '' }
                return { ...state, ...(newState as registerState) }
            })
            .addCase(RegisterAsync.fulfilled, (state) => {
                return { ...state, status: 'succeeded', message: '' }
            })
            .addCase(RegisterAsync.rejected, (state, { error }) => {
                const newState = { ...initialState, status: 'failed', messages: error.message as string }
                return { ...state, ...(newState as registerState) }
            })
    },
})
export const { clearState } = registerSlice.actions
export const selectRigister = (state: RootState) => state.register
export default registerSlice.reducer
