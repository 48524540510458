import React from 'react'
import { AiOutlineSend, AiOutlineUsergroupAdd } from 'react-icons/ai'
import { MdAddAlert, MdOutlineSwapHoriz } from 'react-icons/md'
export interface menuProps {
    name: string
    asideName: string
    href: string
    icon: React.ElementType
}
const AdminMenuLeftConfig = [
    // {
    //     name: 'Thay đổi gói đầu tư',
    //     asideName: 'AdminPacket',
    //     href: '/admin/thay-doi-lai-suat.html',
    //     icon: MdSettingsEthernet,
    // },

    {
        name: 'Quản lý tài khoản',
        asideName: 'ManagementUser',
        href: '/admin/quan-ly-tai-khoan.html',
        icon: AiOutlineUsergroupAdd,
    },

    {
        name: 'Swap',
        asideName: 'SwapAdmin',
        href: '/admin/swap.html',
        icon: MdOutlineSwapHoriz,
    },
    {
        name: 'Danh sách rút coin',
        asideName: 'AdminWithdraw',
        href: '/admin/danh-sach-rut-tien.html',
        icon: AiOutlineSend,
    },
    {
        name: 'Cài đặt thông báo',
        asideName: 'AdminAlert',
        href: '/admin/cai-dat-thong-bao.html',
        icon: MdAddAlert,
    },
] as Array<menuProps>

export default AdminMenuLeftConfig
