import useScrollTrigger from '@mui/material/useScrollTrigger'
import Fade from '@mui/material/Fade'
import Box from '@mui/material/Box'

interface Props {
    window?: () => Window
    children: React.ReactElement
}

const ScrollTop = (props: Props) => {
    const { children, window } = props
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
        disableHysteresis: true,
        threshold: 100,
    })

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        const anchor = ((event.target as HTMLDivElement).ownerDocument || document).querySelector('#back-to-top-anchor')

        if (anchor) {
            anchor.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            })
        }
    }

    return (
        <Fade in={trigger}>
            <Box onClick={handleClick} role="presentation" sx={{ zIndex: 1000, position: 'fixed', bottom: 100, right: 27 }}>
                {children}
            </Box>
        </Fade>
    )
}

export default ScrollTop
