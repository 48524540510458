import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { apiGet, apiPost } from '../../../hooks/api'
import { RootState } from '../../app/store'

export interface roleProps {
    customer: boolean
    admin: boolean
}

export interface walletProps {
    balance: number
    idoAddress: string
    withdraw: boolean
    withdrawCoin: boolean
}

export interface loginState {
    _id: string
    username: string
    parent_ref: string
    messages: string
    token: string
    status: 'idle' | 'loading' | 'failed' | 'succeeded'
    statusLoading: 'idle' | 'loading' | 'failed' | 'succeeded'
    information: informationState
    role: roleProps
    createdAt?: Date
    updatedAt?: Date
    wallet: walletProps
    txn: number
}

export interface informationState {
    bankName: string
    bankBranch: string
    bankAccount: string
    bankNumber: string
}

interface userLoginState {
    username: string
    password: string
    captcha: number | string
}

export const initialState: loginState = {
    _id: '',
    username: '',
    parent_ref: '',
    messages: '',
    token: '',
    status: 'idle',
    statusLoading: 'idle',
    txn: 0,
    information: {
        bankName: '',
        bankBranch: '',
        bankAccount: '',
        bankNumber: '',
    },
    role: {
        customer: true,
        admin: false,
    },
    wallet: { balance: 0, idoAddress: '', withdraw: false, withdrawCoin: false },
}

export const LoginAsync = createAsyncThunk('user/login', async (data: userLoginState) => {
    return await apiPost({ ...data }, 'user/sign-in')
})

export const LoadingUserAsync = createAsyncThunk('user/login/loading', async (token: string) => {
    return await apiGet(`user/load-user?access_token=${token}`)
})

export const userSlice = createSlice({
    name: 'user/loign',
    initialState,
    reducers: {
        clearState: () => initialState,
        clearAsync: (state) => {
            return { ...state, status: 'idle' }
        },
        setToken: (state, { payload }) => {
            return { ...state, token: payload }
        },
        updateInformation: (state, { payload }) => {
            return { ...state, information: payload }
        },
        updateWallet: (state, { payload }) => {
            return { ...state, wallet: payload }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(LoginAsync.pending, (state) => {
                return { ...state, status: 'loading', messages: '' }
            })
            .addCase(LoginAsync.fulfilled, (state, { payload }) => {
                const newState = {
                    ...(payload as loginState),
                    status: 'succeeded',
                    statusLoading: 'sucessded',
                    messages: '',
                }
                localStorage.setItem('token', newState.token)
                return { ...state, ...(newState as loginState) }
            })
            .addCase(LoginAsync.rejected, (state, { error }) => {
                const newState = {
                    ...initialState,
                    messages: error.message as string,
                    status: 'failed',
                }
                return { ...state, ...(newState as loginState) }
            })
            .addCase(LoadingUserAsync.pending, (state) => {
                return { ...state, statusLoading: 'loading', messages: '' }
            })
            .addCase(LoadingUserAsync.fulfilled, (state, { payload }) => {
                const newState = {
                    ...(payload as loginState),
                    status: 'sucessded',
                    statusLoading: 'succeeded',
                    messages: '',
                }
                return { ...state, ...(newState as loginState) }
            })
            .addCase(LoadingUserAsync.rejected, (state, { error }) => {
                const newState = { ...initialState, messages: error.message as string }
                return { ...state, ...(newState as loginState) }
            })
    },
})
export const { updateInformation, clearState, clearAsync, setToken, updateWallet } = userSlice.actions
export const selectLogin = (state: RootState) => state.login
export default userSlice.reducer
