import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import useApi from '../../../../hooks/useApi'
import { RootState } from '../../../app/store'
import { walletProps } from '../LoginSlice'

interface WithdrawlState {
    message: string
    status: 'idle' | 'loading' | 'failed' | 'succeeded'
    wallet: walletProps
}

interface postPros {
    token: string
    password: string
    amount: number
    client: string
    address: string
}

const initialState: WithdrawlState = {
    message: '',
    status: 'idle',
    wallet: { balance: 0, idoAddress: '', withdraw: false, withdrawCoin: false },
}

export const WithdrawAsync = createAsyncThunk('user/withdraw/submit', async (data: postPros) => {
    const { apiPost } = useApi()
    return await apiPost({ ...data, token: '' }, `user/withdraw?access_token=${data.token}`)
})

export const WithdrawSlice = createSlice({
    name: 'user/withdraw',
    initialState,
    reducers: {
        clearState: () => initialState,
        setSuccess: (state) => {
            return { ...state, status: 'succeeded', message: 'Rút tiền thành công vui lòng đợi chuyển khoản' }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(WithdrawAsync.pending, (state) => {
                const newState = { ...initialState, status: 'loading', message: '' }
                return { ...state, ...(newState as WithdrawlState) }
            })
            .addCase(WithdrawAsync.fulfilled, (state) => {
                const newState = { ...initialState, status: 'loading' }
                return { ...state, ...(newState as WithdrawlState) }
            })
            .addCase(WithdrawAsync.rejected, (state, { error }) => {
                const newState = { ...initialState, status: 'failed', message: error.message as string }
                return { ...state, ...(newState as WithdrawlState) }
            })
    },
})

export const { clearState, setSuccess } = WithdrawSlice.actions
export const selectWithdraw = (state: RootState) => state.withdraw
export default WithdrawSlice.reducer
